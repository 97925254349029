import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
//Components
import Layout from "../../components/Layout/Layout"
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
// import PostSidebar from "../../components/PostSidebar/PostSidebar"
//Utils
import TransformOembedToIframe from "../../utils/TransformOembedToIframe"

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
`

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

const PostContent = styled.article`
  margin-top: 20px;
  max-width: 100%;

  h1 {
    font-family: "Gobold", sans-serif;
    line-height: 60px;
    font-weight: 100;
    letter-spacing: 4.5px;
    text-align: center;
  }

  a {
    color: #fff;
  }

  a:hover {
    color: #60c1aa;
  }

  iframe {
    width: 100%;
  }

  blockquote {
    font-size: 1rem !important ;
    text-align: right;
    border-left: 2px solid #fff;
    font-family: "Times New Roman", Times, serif;
    p {
      text-align: right;
      font-family: "Times New Roman", Times, serif;
    }
    cite {
      text-align: right;
    }
  }

  figcaption {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;

    @media (max-width: 768px) {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    @media (min-width: 768px) {
      text-align: justify;
      direction: ltr;
      word-break: break-word;
    }
  }
  img {
    width: 100% !important;
    border: solid 5px #ffffff;
  }

  .content {
    padding: 0.5rem;
  }

  @media (min-width: 450px) {
    .content {
      padding: 1rem;
    }
  }

  @media (min-width: 768px) {
    .content {
      padding: 1.5rem;
    }
  }

  @media (min-width: 992px) {
    .content {
      padding: 2rem;
    }
  }
`

const PostTemplate = ({ data }) => (
  <Layout>
    <Wrapper>
      <BreadCrumb parent={{ uri: "/blog/all-posts", title: "blog" }} />
      <ContentWrapper>
        <PostContent>
          <h1 dangerouslySetInnerHTML={{ __html: data.post.title }} />
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: TransformOembedToIframe(data.post.content),
            }}
          />
        </PostContent>
      </ContentWrapper>
    </Wrapper>
  </Layout>
)

export default PostTemplate

export const PageQuery = graphql`
  query($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      content
      author {
        node {
          name
        }
      }
      date(formatString: "DD MM YYYY")
      categories {
        nodes {
          id
          name
          uri
          slug
        }
      }
    }
  }
`
